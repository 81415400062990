.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter, .dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #FFF;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

/* Peter */

body {
  font-family: "Roboto Light", sans-serif;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #141414;
  text-align: left;
  background-color: #F2F2F2;
  font-size: .95rem;
}

.main-content{
  overflow-x: hidden;
}

body[data-layout="horizontal"] .page-content{
  margin-top: 70px;
}

#page-topbar {
  background-color: #1E1E1E !important;
  padding: 10px 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #141414;
}

h1, h2, h3, h4, h5, h5, strong {
  font-family: "Roboto Medium";
  font-weight: unset !important;
}

.card-title, .table th, .font-weight-bold, .form-group label {
  font-family: "Roboto Medium";
  font-weight: unset !important;
}

.footer {
  left: 0 !important;

  a {
    color: $footer-color;
  }
}

.box-icon{
  position: absolute;
  top:10px;
  right:25px;
}


.navbar-header .dropdown.show .header-item,
.dropdown-item:hover, .dropdown-item:focus{
  background-color: transparent;
}

.card-quicknav {
  &:after {
    content: "";
    position: absolute;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.1);
    transform: rotate(32deg);
    top: -20px;
    right: 40px;
    width: 12px;
    transition: all 0.2s;
    left: auto;
  }

  .mdi-arrow-right {
    position: absolute;
    bottom: 0;
    right: 5px;
  }
}

.form-control {
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #141414;
}

.form-control::placeholder {
  color: #495057;
}

.form-control[disabled] {
  background-color: $light;
}

.topnav {
  background-color: $teal;
  z-index: 200;
  margin-top: 70px;
}

.topnav .navbar-nav .nav-link {
  font-family: "Roboto Medium";
  color: #fff !important;
  font-size: 16px;
}

body[data-layout="horizontal"][data-topbar="light"] .topnav .navbar-nav > .dropdown.active > a,
.topnav .navbar-nav > .dropdown.active > a {
  opacity: 1;
  color: $yellow !important;
}

.topnav .navbar-nav .nav-link i {
  color: #fff !important;
}

.navbar-header .btn{
  margin-top: 13px;
}
body[data-topbar="dark"] .header-item:hover,
body[data-topbar="dark"] .header-item,
body[data-topbar="dark"] .noti-icon i,
.navbar-header .noti-icon i{
  color: #fff;
}

.btn {
  outline: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.bg-dark {
  background-color: $teal !important;
}

.btn-dark {
  background-color: $teal;
}

.welcome-picture {
  position: absolute;
  bottom: -25px;
  right: 15px;
  width: 150px;
}

.card-title,
.modal-title {
  color: $yellow;
}

.card-bordered {
  border: 1px solid $light;
}

.dispatch-table .thead-light th {
  background-color: transparent;
  border-color: transparent;
}

.dispatch-search {
  position: absolute;
  top: 10px;
  right: 20px;
  padding-left: 30px;

  i {
    position: absolute;
    top: 10px;
    left: 5px;
  }
}

.custom-radio label,
.custom-checkbox label {
  font-size: 14px;
}

.bg-gray {
  background-color: #F8F9FA;
}

.full-height {
  height: 100%;
}

.av-select .av__control {
  font-size: 13px;
}

.av-select .av__menu {
  font-size: 13px;
  z-index:100
}

.service-option {
  background-color: $light;
  height:auto;

  &.is-disabled {
    background-color: transparent;
  }
}

.badge-soft-success {
  color: #268462;
  background-color: rgba(52, 195, 143, .18);
}

.badge-soft-warning {
  color: #8C8530;
  background-color: rgba(188, 178, 67, .18);
}

.badge-soft-danger {
  color: #CE5757;
  background-color: rgba(244, 106, 106, .18);
}

table th {
  color: #141414 !important;
}

.table .thead-light th {
  padding-top: 10px;
  padding-bottom: 10px;
}

table td {
  color: #464646 !important;
}

.header-item, .header-item:hover {
  color: #fff;
}

.mdb-datatable-filter {
  display: flex;
  @extend .mb-2;

  input {
    @extend .font-size-14;
  }

  &:before {
    content: "\F0349";
    @extend .mdi-set;
    @extend .mr-1;
    display: block;
    font-size: 24px;
  }
}

.link-highlight {
  @extend .font-weight-bold;
  @extend .text-yellow;
}

.badge-soft-yellow {
  color: #6A6528;
  background-color: rgba(188,178,67,.18);
}
.badge-soft-info {
  color: #1E619D;
  background-color: rgba(80,165,241,.18);
}

.verti-timeline .event-list {
  position: relative;
  padding: 0 0 15px 20px;
}
.verti-timeline .event-list .event-timeline-dot {
  top:1px;
}

.cursor-pointer{
  cursor: pointer;
}

.top-15{
  top:15px;
}
.right-15{
  right:15px;
}

.nofification-text p:last-child{
  margin-bottom: 0;
}
